import { GOOGLE_METRICS, META_METRICS, TIKTOK_METRICS } from "~/shared/config";
import {
  formatFractionalPercentage,
  formatInteger,
  formatNumber,
  formatPercentage,
  formatSeconds,
  getFormatCurrency,
  groupBy,
} from "~/shared/utils";
import {
  MetaMetric,
  TiktokMetric,
  Ga4Metric,
  ga4MetricToFormatValueFunction,
  tiktokMetricToFormatValueFunction,
  metaMetricToFormatValueFunction,
  metricToPercentageUpColor,
  ValueFormatter,
  googleMetricToFormatValueFunction,
  GoogleMetric,
  CustomMetricRule,
  CustomMetricRuleResponse,
  CUSTOM_METRIC_NAME_PREFIX,
  AggregateFunction,
} from "~/types/Metrics";
import {
  Config,
  ConfigWithGroupAndDescriptionMaybe,
  Provider,
} from "~/types/shared";

const GA4_PREFIX = "ga4:";

export const useMetrics = () => {
  const getMetaMetrics = () => {
    const { hasFeature } = useFeatureFlags();
    return useGetEnabledConfig(META_METRICS).filter((m) =>
      typeof m.featureFlag === "string" ? hasFeature(m.featureFlag) : true,
    );
  };

  const getTiktokMetrics = () => {
    const { hasFeature } = useFeatureFlags();
    return useGetEnabledConfig(TIKTOK_METRICS).filter((m) =>
      typeof m.featureFlag === "string" ? hasFeature(m.featureFlag) : true,
    );
  };

  const getGoogleMetrics = () => {
    const { hasFeature } = useFeatureFlags();
    return useGetEnabledConfig(GOOGLE_METRICS).filter((m) =>
      typeof m.featureFlag === "string" ? hasFeature(m.featureFlag) : true,
    );
  };

  const metricToDisplayName = (
    metric: string,
    provider: Provider,
    options?: Partial<{
      customConversionNames?: Record<string, string>;
      customMetricRules?: CustomMetricRule[];
    }>,
  ) => {
    const opts = options || {};
    const metricConfig: Array<Config<string>> = [];

    const isGa4 = isGa4Metric(metric);
    const conversionMetricMaybe = conversionMetricToDisplayName(
      metric,
      opts.customConversionNames,
    );

    if (typeof conversionMetricMaybe === "string") {
      return conversionMetricMaybe;
    }

    const customMetricNames = customMetricRulesToCustomMetricNames(
      opts.customMetricRules || [],
    );
    const customMetricMaybe = conversionMetricToDisplayName(
      metric,
      customMetricNames,
    );

    if (typeof customMetricMaybe === "string") {
      return customMetricMaybe;
    }

    // In creative reporting we mix GA and Ad Platforms --> Thus we need this check here
    if (isGa4) {
      metricConfig.push(...providerToMetricConfig(Provider.GA4));
    } else {
      metricConfig.push(...providerToMetricConfig(provider));
    }

    metric = metric.replace(GA4_PREFIX, "");
    const idx = metricConfig.findIndex((config) => config.id === metric);

    if (idx < 0) {
      return metric;
    }

    if (isGa4) {
      return "GA4:" + metricConfig[idx].displayName;
    } else {
      return metricConfig[idx].displayName;
    }
  };

  const customMetricRulesToCustomMetricNames = (
    customMetricRules: CustomMetricRule[],
  ): Record<string, string> => {
    return Object.fromEntries(
      customMetricRules.map((rule) => [
        rule.metricName,
        rule.metricDisplayName,
      ]),
    );
  };

  const isGa4Metric = (
    metric: string,
    usePrefix = true,
  ): metric is Ga4Metric => {
    if (usePrefix) {
      const isGa4 = metric.startsWith(GA4_PREFIX);
      const parts = metric.split(":");

      return (
        isGa4 &&
        parts.length === 2 &&
        Object.values(Ga4Metric).includes(parts[1] as Ga4Metric)
      );
    }

    return Object.values(Ga4Metric).includes(metric as Ga4Metric);
  };

  const conversionMetricToDisplayName = (
    conversionMetric: string,
    customConversionNames: Record<string, string> = {},
  ) => {
    return conversionMetric in customConversionNames
      ? customConversionNames[conversionMetric]
      : null;
  };

  const providerToMetricConfig = (
    provider: Provider,
  ): ConfigWithGroupAndDescriptionMaybe<string>[] => {
    switch (provider) {
      case Provider.TIKTOK:
        return getTiktokMetrics();
      case Provider.GOOGLE:
        return getGoogleMetrics();
      default:
        return getMetaMetrics();
    }
  };

  const getProviderDefaultMetric = (provider: Provider) => {
    switch (provider) {
      case Provider.TIKTOK:
        return TiktokMetric.clicks;
      case Provider.GOOGLE:
        return GoogleMetric.clicks;
      default:
        return MetaMetric.clicks;
    }
  };

  const getMetricOptions = (
    provider: Provider[] | Provider,
    options?: Partial<{
      searchQuery?: string;
      customConversionNames?: Record<string, string>;
      customMetricRules?: CustomMetricRule[];
      prefixImgs?: boolean;
      prefixProvider?: boolean;
      showOwnRules?: boolean;
      showThirdPartyRules?: boolean;
    }>,
  ) => {
    const opts = options || {};
    const _searchQuery = opts.searchQuery ? opts.searchQuery.toLowerCase() : "";
    const providers = Array.isArray(provider) ? [...provider] : [provider];
    const { getProviderLogos } = useConnection();
    const metricsOptions = providers
      .map((provider) => {
        const metricConfig = providerToMetricConfig(provider).filter((metric) =>
          metric.displayName.toLowerCase().includes(_searchQuery),
        );
        const providerLogos = getProviderLogos(provider);
        return {
          metricConfig,
          providerLogos,
          provider,
        };
      })
      .flatMap(({ metricConfig, providerLogos, provider }) =>
        metricConfig.map((metric) => ({
          value:
            opts.prefixProvider === true
              ? `${provider}:${metric.id}`
              : metric.id,
          displayValue: metric.displayName,
          group: metric.group,
          tooltipMaybe: metric.description,
          prefixImgs: opts.prefixImgs === true ? providerLogos : undefined,
          customMetricId: null as number | null,
        })),
      );
    const customConversionNames = opts.customConversionNames || {};
    const customConversionOptions = Object.entries(customConversionNames)
      .filter(([_, displayName]) =>
        displayName.toLowerCase().includes(_searchQuery),
      )
      .map(([metric, displayName]) => {
        const adAccountStore = useAdAccountStore();
        const providerMaybe = adAccountStore.providerOfCustomConversion(metric);
        const providerLogos = providerMaybe
          ? getProviderLogos(providerMaybe)
          : undefined;
        return {
          value:
            opts.prefixProvider === true
              ? `${providerMaybe}:${metric}`
              : metric,
          displayValue: displayName,
          group: "Custom Conversion",
          tooltipMaybe: "",
          prefixImgs: opts.prefixImgs === true ? providerLogos : undefined,
          customMetricId: null as number | null,
        };
      });
    const customMetricRules = opts.customMetricRules || [];
    const ownRules = customMetricRules.filter(
      (rule) => rule.customMetricRuleId != null && rule.customMetricRuleId > 0,
    );
    const thirdPartyRules = customMetricRules.filter(
      (rule) => rule.customMetricRuleId == null,
    );
    const thirdPartyOptions = getCustomMetricOptions({
      groupName: "3rd Party Metrics",
      rules: thirdPartyRules,
      prefixImgs: opts.prefixImgs,
      prefixProvider: opts.prefixProvider,
      searchQuery: _searchQuery,
    });
    const ownRuleOptions = getCustomMetricOptions({
      groupName: "Custom Metrics",
      rules: ownRules,
      prefixImgs: opts.prefixImgs,
      prefixProvider: opts.prefixProvider,
      searchQuery: _searchQuery,
    });
    const allOptions = [...metricsOptions, ...customConversionOptions];
    if (opts.showThirdPartyRules !== false) {
      allOptions.push(...thirdPartyOptions);
    }
    if (opts.showOwnRules !== false) {
      allOptions.push(...ownRuleOptions);
    }
    return groupBy(allOptions, "group");
  };

  const getCustomMetricOptions = (dto: {
    rules: CustomMetricRule[];
    searchQuery: string;
    prefixProvider: boolean | undefined;
    prefixImgs: boolean | undefined;
    groupName: string;
  }) => {
    const { getProviderLogos } = useConnection();
    const getTooltip = (metricName: string) =>
      dto.rules.find((rule) => rule.metricName === metricName)?.description ||
      "";
    const getCustomMetricId = (metricName: string) =>
      dto.rules.find((rule) => rule.metricName === metricName)
        ?.customMetricRuleId || null;
    const customMetricNames = customMetricRulesToCustomMetricNames(dto.rules);
    return Object.entries(customMetricNames)
      .filter(([_, displayName]) =>
        displayName.toLowerCase().includes(dto.searchQuery),
      )
      .map(([metric, displayName]) => {
        const adAccountStore = useAdAccountStore();
        const providerMaybe = adAccountStore.providerOfCustomMetric(metric);
        const providerLogos = providerMaybe
          ? getProviderLogos(providerMaybe)
          : undefined;
        return {
          value:
            dto.prefixProvider === true ? `${providerMaybe}:${metric}` : metric,
          displayValue: displayName,
          group: dto.groupName,
          tooltipMaybe: getTooltip(metric),
          prefixImgs: dto.prefixImgs === true ? providerLogos : undefined,
          customMetricId: getCustomMetricId(metric),
        };
      });
  };

  const getValueFormatterType = (
    metric: string,
    provider: Provider,
    customMetricRules?: CustomMetricRule[],
  ): ValueFormatter => {
    // In creative reporting we mix GA and Ad Platforms --> Thus we need this check here
    if (isGa4Metric(metric)) {
      return ga4MetricToFormatValueFunction[
        metric.replace(GA4_PREFIX, "") as Ga4Metric
      ];
    }

    const ruleMaybe = customMetricRules?.find(
      (rule) => rule.metricName === metric,
    );

    if (ruleMaybe) {
      return ruleMaybe.formatter;
    }

    switch (provider) {
      case Provider.TIKTOK:
        return tiktokMetricToFormatValueFunction[metric as TiktokMetric];
      case Provider.GA4:
        return ga4MetricToFormatValueFunction[metric as Ga4Metric];
      case Provider.GOOGLE:
        return googleMetricToFormatValueFunction[metric as GoogleMetric];
      default:
        return metaMetricToFormatValueFunction[metric as MetaMetric];
    }
  };

  const getValueFormatter = (
    metric: string,
    provider: Provider,
    currency = "EUR",
    customMetricRules?: CustomMetricRule[],
  ) => {
    const formatter = getValueFormatterType(
      metric,
      provider,
      customMetricRules,
    );
    return getValueFormatterFunc(formatter, currency);
  };

  const getValueFormatterFunc = (
    formatter: ValueFormatter,
    currency: string,
  ): ((...args: any[]) => string) => {
    switch (formatter) {
      case "currency":
        return getFormatCurrency(currency);
      case "integer":
        return formatInteger;
      case "number":
        return formatNumber;
      case "percentage":
      case "decimalPercentage":
        return formatPercentage;
      case "decimalAsPercentage":
        return formatFractionalPercentage;
      case "seconds":
        return formatSeconds;
      default:
        return formatNumber;
    }
  };

  const getPercent = (
    curValue: number | undefined | null,
    compareValue: number | undefined | null,
  ) => {
    if (curValue == null || compareValue == null) {
      return "?";
    }

    const difference = curValue - compareValue;
    const fraction = compareValue > 0 ? difference / compareValue : NaN;
    const percentage = formatPercentage(fraction, 0);

    if (isNaN(fraction)) {
      return "?";
    }

    return fraction >= 0.005
      ? "↑ " + percentage
      : fraction <= -0.005
        ? "↓ " + percentage
        : "→ " + percentage;
  };

  const getPercentColor = (
    percentStr: string | undefined | null,
    metric: string,
  ): "neutral" | "green" | "red" => {
    if (percentStr == null || percentStr[0] === "?") {
      return "neutral";
    }

    if (isGa4Metric(metric)) {
      metric = metric.replace(GA4_PREFIX, "");
    }

    let color: "neutral" | "green" | "red" = "neutral";

    if (percentStr[0] === "↑") {
      color = metricToPercentageUpColor[metric];
    } else if (percentStr[0] === "↓") {
      color = metricToPercentageUpColor[metric] === "green" ? "red" : "green";
    } else {
      color = "neutral";
    }

    return color;
  };

  const getDefaultMetric = (provider: Provider) => {
    switch (provider) {
      case Provider.TIKTOK:
        return TiktokMetric.clicks;
      case Provider.GOOGLE:
        return GoogleMetric.clicks;
      default:
        return MetaMetric.clicks;
    }
  };

  const getMappedCustomMetricRules = (
    rules: Array<CustomMetricRuleResponse>,
  ): Array<CustomMetricRule> => {
    const mappedRules = rules.map((rule) => ({
      customMetricRuleId: rule.id,
      metricName: titleToMetricName(rule.title, rule.id),
      metricDisplayName: rule.title,
      description: rule.description,
      aggregateFunction: AggregateFunction.EXPRESSION,
      numerator: "",
      denominator: "",
      weightedBy: "",
      formatter: rule.formatter,
      sort: rule.sort,
      expression: rule.expression,
    }));

    mappedRules.sort((a, b) => a.customMetricRuleId - b.customMetricRuleId);

    return mappedRules;
  };

  const titleToMetricName = (metricTitle: string | null, ruleId: number) => {
    if (metricTitle == null || metricTitle === "") {
      return "";
    }
    return (
      CUSTOM_METRIC_NAME_PREFIX +
      metricTitle
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .replace(/\s/g, "_")
        .toLowerCase() +
      ruleId
    );
  };

  const secondaryMetricsToPrimaryMetric = (
    secondaryMetrics: string[],
    provider: Provider,
  ) => {
    if (secondaryMetrics.length === 0) {
      return getDefaultMetric(provider);
    }
    return secondaryMetrics[0];
  };

  return {
    getMetaMetrics,
    getTiktokMetrics,
    metricToDisplayName,
    isGa4Metric,
    getValueFormatterType,
    getValueFormatter,
    getPercent,
    getPercentColor,
    getDefaultMetric,
    getMetricOptions,
    providerToMetricConfig,
    customMetricRulesToCustomMetricNames,
    getMappedCustomMetricRules,
    titleToMetricName,
    getProviderDefaultMetric,
    secondaryMetricsToPrimaryMetric,
  };
};
